import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NotFoundPage = () => (
  <Layout>
  <div className="404-page" style={{padding: "30px"}}>
    <SEO title="404: Not found" />
    <h1>Page non trouvée</h1>
    <p>Vous venez de frapper une route qui n'existe pas</p>
    </div>
  </Layout>
)

export default NotFoundPage
